import React, { useState, useLayoutEffect } from 'react';
import Styleswrapper from '../../components/StylesWrapper';
import PageMeta from '../../components/blocks/helmet/PageMeta';
import { gsap, ScrollTrigger } from "gsap/all";
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

import './index.css';


// icons
import SunIcon from '../../images/icons/sun.png';
import MoonIcon from '../../images/icons/moon.png';

const Apple = () => {
    gsap.registerPlugin(ScrollTrigger);

    const [clockGreeting, setClockGreeting] = useState([]);
    const currentHour = new Date().getHours();

    useLayoutEffect(() => {
        if (currentHour >= 5 && currentHour < 12) {
            setClockGreeting(["Good morning", "☀️", SunIcon]);

        }
        else if (currentHour > 11 && currentHour < 17) {
            setClockGreeting(["Good afternoon", "☀️", SunIcon]);

        }
        else if (currentHour > 16 && currentHour <= 23) {
            setClockGreeting(["Good evening", "🌙", MoonIcon]);

        }
        else {
            setClockGreeting(["Good health to you", "🌙", MoonIcon]);

        }
    }, [currentHour]);

    useLayoutEffect(() => {
        let bgTl = gsap.timeline();

        let introTl = gsap.timeline({
            defaults: {
                ease: "power1.out",
                duration: 2,
            }
        });

        introTl.to(".master-bg-block", {
            "--bg-1": "#21084D",
            "--bg-2": "#00070D",
            "--bg-3": "#00070D",
            "--bg-4": "#00070D",
            "--bg1-stop": "0%",
            "--bg2-stop": "20%",
            "--bg3-stop": "100%",
            "--bg4-stop": "100%",
        });


        let scrollTl = gsap.timeline({
            scrollTrigger: {
                trigger: ".apple-secondary-wrapper",
                start: "top bottom",
                end: "bottom bottom",
                scrub: 0.6,
            },
            defaults: {
                ease: "none",
                duration: 1,
            }
        });

        scrollTl.to(".master-bg-block", {
            "--bg-1": "#001629",
            "--bg1-stop": "0%",
        })
            .to(".master-bg-block", {
                "--bg-2": "#190045",
                "--bg2-stop": "70%",
            }, 0)
            .to(".master-bg-block", {
                "--bg-3": "#190045",
                "--bg3-stop": "70%",
            }, 0)
            .to(".master-bg-block", {
                "--bg-4": "#ffffff",
                "--bg4-stop": "100%",
            }, 0);

        bgTl.add(introTl);
        bgTl.add(scrollTl);

        return () => {
            bgTl.kill();
        }
    }, []);

    // ICON
    const greetingIconVariant = {
        initial: {
            opacity: 0,
            y: "100%",
            rotate: -15,
        },
        animate: {
            opacity: 1,
            y: 0,
            rotate: 0,
            transition: {
                duration: 1,
                ease: "circOut",
            }
        },
    };

    // GREETING MESSAGE
    const greetingMessageVariant = {
        initial: {
            opacity: 0,
            y: "100%",
        },
        animate: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.8,
                ease: "circOut",
                delay: 0.1,
            }
        },
    };

    const paragraphVariant = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
            transition: {
                duration: 1,
                ease: "circOut",
                delay: 0.2,
            }
        },
    };

    useLayoutEffect(() => {
        let masterTL = gsap.timeline();

        masterTL.to(".prism-art svg #artwork #line_1 #ray", {
            opacity: 0,
            duration: 3,
        });
        
        

        // let introTl = gsap.timeline({
        //     defaults: {
        //         ease: "power1.out",
        //         duration: 2,
        //     }
        // });
    }, []);


    return (
        <Styleswrapper
            bgColor1="#00070D"
            bgColor2="#00070D"
            bg1Stop="0%"
            bg2Stop="20%"
            noise="true"
        >

            <PageMeta
                theme="#21084D"
                title="Apple — Nihar Bhagat"
                description=""
                image="../images/social/contact/default.jpg"
                twitterImage="../images/social/contact/twitter.jpg"
            />

            <section className="apple-main">
                <div className="apple-main-wrapper">
                    <div className="header">
                        <div className="greeting-icon-wrapper">
                            <div className="greeting-icon">
                                <motion.img
                                    src={clockGreeting[2]}
                                    alt={clockGreeting[1]}
                                    loading='eager'
                                    draggable='false'
                                    variants={greetingIconVariant}
                                    initial="initial"
                                    animate="animate"
                                />
                            </div>
                        </div>
                        <div className="greeting-message-wrapper">
                            <motion.p className="greeting-message"
                                variants={greetingMessageVariant}
                                initial="initial"
                                animate="animate"
                            >
                                {clockGreeting[0]}
                            </motion.p>
                        </div>
                        <motion.h1
                            variants={greetingMessageVariant}
                            initial="initial"
                            animate="animate"
                        >
                            My name is
                            <p className="highlight">
                                Nihar Bhagat
                            </p>
                        </motion.h1>
                        <motion.h2
                            variants={greetingMessageVariant}
                            initial="initial"
                            animate="animate"
                        >and this is my musing on
                            <p className="highlight">
                                Tools
                            </p>
                        </motion.h2>
                    </div>
                    <div className="prism-art">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1387.552" height="1827.073" viewBox="0 0 1387.552 1827.073">
                            <g id="artwork" transform="translate(-32.448 -331.663)">
                                <g id="lines">
                                    <g id="line_1" transform="translate(107.125 -169.142) rotate(16)">
                                        <g id="ray" transform="translate(1182.5 464)">
                                            <rect id="Rectangle_1072" data-name="Rectangle 1072" width="204" height="4" rx="2" fill="#f33" />
                                        </g>
                                        <text id="when_I_should_ve_been_studying" data-name="when I should&apos;ve been studying" transform="translate(828.5 472)" fill="#f33" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em" className="luminosity"><tspan x="0" y="0">WHEN I SHOULD&apos;VE BEEN STUDYING</tspan></text>
                                        <g id="ray-2" data-name="ray" transform="translate(700 464)">
                                            <rect id="Rectangle_1072-2" data-name="Rectangle 1072" width="107.5" height="4" rx="2" fill="#f33" />
                                        </g>
                                        <text id="restoring_tables" data-name="restoring tables" transform="translate(500 472)" fill="#f33" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em" className="luminosity"><tspan x="0" y="0">RESTORING TABLES</tspan></text>
                                        <g id="Group_3510" data-name="Group 3510">
                                            <text id="It_began_with" data-name="It began with" transform="translate(265 459)" fill="#f33" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em"><tspan x="0" y="0">IT BEGAN WITH</tspan></text>
                                            <g id="ray-3" data-name="ray" transform="translate(265 464)">
                                                <rect id="Rectangle_1072-3" data-name="Rectangle 1072" width="215" height="4" rx="2" fill="#f33" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="line_2" transform="translate(154.644 -107.415) rotate(14)">
                                        <g id="ray-4" data-name="ray" transform="translate(1105.417 464)">
                                            <rect id="Rectangle_1072-4" data-name="Rectangle 1072" width="203" height="4" rx="2" fill="#ff9a33" />
                                        </g>
                                        <text id="when_I_should_ve_been_studying-2" data-name="when I should&apos;ve been studying" transform="translate(752.416 472)" fill="#ff9a33" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em" className="luminosity"><tspan x="0" y="0">WHEN I SHOULD&apos;VE BEEN STUDYING</tspan></text>
                                        <g id="ray-5" data-name="ray" transform="translate(677.666 464)">
                                            <rect id="Rectangle_1072-5" data-name="Rectangle 1072" width="53.75" height="4" rx="2" fill="#ff9a33" />
                                        </g>
                                        <text id="designing_apps_and_websites" data-name="designing apps and websites" transform="translate(356.667 472)" fill="#ff9a33" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em" className="luminosity"><tspan x="0" y="0">DESIGNING APPS AND WEBSITES</tspan></text>
                                        <g id="ray-6" data-name="ray" transform="translate(265 464)">
                                            <rect id="Rectangle_1072-6" data-name="Rectangle 1072" width="71.667" height="4" rx="2" fill="#ff9a33" />
                                        </g>
                                    </g>
                                    <g id="line_3" transform="matrix(0.978, 0.208, -0.208, 0.978, 200.022, -51.077)">
                                        <g id="ray-7" data-name="ray" transform="translate(1034.837 464)">
                                            <rect id="Rectangle_1072-7" data-name="Rectangle 1072" width="198.663" height="4" rx="2" fill="#fff833" />
                                        </g>
                                        <text id="when_I_should_ve_been_studying-3" data-name="when I should&apos;ve been studying" transform="translate(680.837 472)" fill="#fff833" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em" className="luminosity"><tspan x="0" y="0">WHEN I SHOULD&apos;VE BEEN STUDYING</tspan></text>
                                        <g id="ray-8" data-name="ray" transform="translate(552.337 464)">
                                            <rect id="Rectangle_1072-8" data-name="Rectangle 1072" width="107.5" height="4" rx="2" fill="#fff833" />
                                        </g>
                                        <text id="creating_entire_brands" data-name="creating entire brands" transform="translate(287.337 472)" fill="#fff833" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em" className="luminosity"><tspan x="0" y="0">CREATING ENTIRE BRANDS</tspan></text>
                                        <g id="ray-9" data-name="ray" transform="translate(159.837 464)">
                                            <rect id="Rectangle_1072-9" data-name="Rectangle 1072" width="107.5" height="4" rx="2" fill="#fff833" />
                                        </g>
                                    </g>
                                    <g id="line_4" transform="matrix(0.985, 0.174, -0.174, 0.985, 10.472, -41.009)">
                                        <g id="ray-10" data-name="ray" transform="translate(832 464)">
                                            <rect id="Rectangle_1072-10" data-name="Rectangle 1072" width="570.5" height="4" rx="2" fill="#3f3" />
                                        </g>
                                        <g id="ray-11" data-name="ray" transform="translate(598.337 464)">
                                            <rect id="Rectangle_1072-11" data-name="Rectangle 1072" width="212.663" height="4" rx="2" fill="#3f3" />
                                        </g>
                                        <g id="ray-12" data-name="ray" transform="translate(469.837 464)">
                                            <rect id="Rectangle_1072-12" data-name="Rectangle 1072" width="107.5" height="4" fill="#3f3" />
                                        </g>
                                        <g id="ray-13" data-name="ray" transform="translate(287.337 464)">
                                            <rect id="Rectangle_1072-13" data-name="Rectangle 1072" width="162.5" height="4" rx="2" fill="#3f3" />
                                        </g>
                                        <g id="ray-14" data-name="ray" transform="translate(104.837 464)">
                                            <rect id="Rectangle_1072-14" data-name="Rectangle 1072" width="162.5" height="4" rx="2" fill="#3f3" />
                                        </g>
                                    </g>
                                    <g id="line_5" transform="translate(243.738 41.399) rotate(8)">
                                        <g id="ray-15" data-name="ray" transform="translate(707.929 464)">
                                            <rect id="Rectangle_1072-15" data-name="Rectangle 1072" width="434.071" height="4" rx="2" fill="#3ff" />
                                        </g>
                                        <text id="a_studio_called_slangbusters" data-name="a studio called slangbusters" transform="translate(367.929 472)" fill="#3ff" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em" className="luminosity"><tspan x="0" y="0">A STUDIO CALLED SLANGBUSTERS</tspan></text>
                                        <g id="ray-16" data-name="ray" transform="translate(239.429 464)">
                                            <rect id="Rectangle_1072-16" data-name="Rectangle 1072" width="107.5" height="4" rx="2" fill="#3ff" />
                                        </g>
                                        <text id="logos" transform="translate(159.429 472)" fill="#3ff" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em" className="luminosity"><tspan x="0" y="0">LOGOS</tspan></text>
                                        <g id="Group_3511" data-name="Group 3511">
                                            <text id="eventually_I_made" data-name="eventually I made" transform="translate(-109.571 455)" fill="#3ff" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em"><tspan x="0" y="0">EVENTUALLY I MADE</tspan></text>
                                            <g id="ray-17" data-name="ray" transform="translate(-109.571 464)">
                                                <rect id="Rectangle_1072-17" data-name="Rectangle 1072" width="249" height="4" rx="2" fill="#3ff" />
                                            </g>
                                        </g>
                                    </g>
                                    <g id="line_6" transform="matrix(0.995, 0.105, -0.105, 0.995, 413.045, 99.759)">
                                        <g id="ray-18" data-name="ray" transform="translate(360.417 464)">
                                            <rect id="Rectangle_1072-18" data-name="Rectangle 1072" width="589.5" height="4" rx="2" fill="#53f" />
                                        </g>
                                        <text id="a_30-min_standup_set" data-name="a 30-min standup set" transform="translate(339.417 472)" fill="#53f" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em" className="luminosity"><tspan x="-220.459" y="0">A 30-MIN STANDUP SET</tspan></text>
                                        <g id="ray-19" data-name="ray" transform="translate(44.667 464)">
                                            <rect id="Rectangle_1072-19" data-name="Rectangle 1072" width="53.75" height="4" rx="2" fill="#53f" />
                                        </g>
                                        <text id="open-mic_posters" data-name="open-mic posters" transform="translate(-158.333 472)" fill="#53f" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em" className="luminosity"><tspan x="0" y="0">OPEN-MIC POSTERS</tspan></text>
                                        <g id="ray-20" data-name="ray" transform="translate(-250 464)">
                                            <rect id="Rectangle_1072-20" data-name="Rectangle 1072" width="71.667" height="4" rx="2" fill="#53f" />
                                        </g>
                                    </g>
                                    <g id="line_7" transform="matrix(0.998, 0.07, -0.07, 0.998, 457.536, 136.255)">
                                        <g id="ray-21" data-name="ray" transform="translate(211 464)">
                                            <rect id="Rectangle_1072-21" data-name="Rectangle 1072" width="673.5" height="4" rx="2" fill="#73f" />
                                        </g>
                                        <text id="pitchdecks_that_close" data-name="pitchdecks that close" transform="translate(190 472)" fill="#73f" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em" className="luminosity"><tspan x="-238.537" y="0">PITCHDECKS THAT CLOSE</tspan></text>
                                        <g id="ray-22" data-name="ray" transform="translate(-177.5 464)">
                                            <rect id="Rectangle_1072-22" data-name="Rectangle 1072" width="107.5" height="4" rx="2" fill="#73f" />
                                        </g>
                                        <text id="names" transform="translate(-197.5 472)" fill="#73f" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em" className="luminosity"><tspan x="-62.512" y="0">NAMES</tspan></text>
                                        <g id="ray-23" data-name="ray" transform="translate(-331.445 464)">
                                            <rect id="Rectangle_1072-23" data-name="Rectangle 1072" width="50.945" height="4" rx="2" fill="#73f" />
                                        </g>
                                    </g>
                                    <g id="line_8" transform="matrix(0.999, 0.035, -0.035, 0.999, 468.244, 167.765)">
                                        <g id="ray-24" data-name="ray" transform="translate(250.416 464)">
                                            <rect id="Rectangle_1072-24" data-name="Rectangle 1072" width="605.75" height="4" rx="2" fill="#93f" />
                                        </g>
                                        <text id="packaging_boxes" data-name="packaging boxes" transform="translate(230.416 472)" fill="#93f" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em" className="luminosity"><tspan x="-173.503" y="0">PACKAGING BOXES</tspan></text>
                                        <g id="ray-25" data-name="ray" transform="translate(-18.334 464)">
                                            <rect id="Rectangle_1072-25" data-name="Rectangle 1072" width="53.75" height="4" rx="2" fill="#93f" />
                                        </g>
                                        <text id="facilitation_workshops" data-name="facilitation workshops" transform="translate(-38.334 472)" fill="#93f" fontSize="14" fontFamily="Inter-Bold, Inter" fontWeight="700" letterSpacing="0.2em" className="luminosity"><tspan x="-249.56" y="0">FACILITATION WORKSHOPS</tspan></text>
                                        <g id="ray-26" data-name="ray" transform="translate(-380.001 464)">
                                            <rect id="Rectangle_1072-26" data-name="Rectangle 1072" width="71.667" height="4" rx="2" fill="#93f" />
                                        </g>
                                    </g>
                                </g>
                                <g id="inner_lines" transform="translate(1395.705 -685.302) rotate(60)" className='prism-lines'>
                                    <g id="ray-27" data-name="ray" transform="translate(1122.648 756.837) rotate(-3)">
                                        <rect id="Rectangle_1072-27" data-name="Rectangle 1072" width="120.26" height="1" rx="0.5" fill="#f33" />
                                    </g>
                                    <g id="ray-28" data-name="ray" transform="matrix(0.999, -0.035, 0.035, 0.999, 1122.647, 754.615)">
                                        <rect id="Rectangle_1072-28" data-name="Rectangle 1072" width="120.26" height="1" rx="0.5" fill="#ff9a33" />
                                    </g>
                                    <g id="ray-29" data-name="ray" transform="matrix(1, -0.017, 0.017, 1, 1122.688, 752.573)">
                                        <rect id="Rectangle_1072-29" data-name="Rectangle 1072" width="120.26" height="1" rx="0.5" fill="#3f3" />
                                    </g>
                                    <g id="ray-30" data-name="ray" transform="translate(1123.683 750.481)">
                                        <rect id="Rectangle_1072-30" data-name="Rectangle 1072" width="118.443" height="1" rx="0.5" fill="#3ff" />
                                    </g>
                                    <g id="ray-31" data-name="ray" transform="matrix(1, 0.017, -0.017, 1, 1122.745, 748.407)">
                                        <rect id="Rectangle_1072-31" data-name="Rectangle 1072" width="120.26" height="1" rx="0.5" fill="#53f" />
                                    </g>
                                    <g id="ray-32" data-name="ray" transform="matrix(0.999, 0.035, -0.035, 0.999, 1122.806, 746.314)">
                                        <rect id="Rectangle_1072-32" data-name="Rectangle 1072" width="120.26" height="1" rx="0.5" fill="#73f" />
                                    </g>
                                    <g id="ray-33" data-name="ray" transform="translate(1122.891 744.212) rotate(3)">
                                        <rect id="Rectangle_1072-33" data-name="Rectangle 1072" width="120.26" height="1" rx="0.5" fill="#93f" />
                                    </g>
                                </g>
                                <g id="ray-34" data-name="ray" transform="matrix(-0.174, 0.985, -0.985, -0.174, 1368.313, 764.278)">
                                    <rect id="Rectangle_1072-34" data-name="Rectangle 1072" width="1415.97" height="4" rx="2" fill="#fff" />
                                </g>
                                <g id="prism" transform="translate(1224.514 -909.629) rotate(60)">
                                    <path id="Rectangle_1094" data-name="Rectangle 1094" d="M12,2A10.011,10.011,0,0,0,2,12v96a10.011,10.011,0,0,0,10,10h96a10.011,10.011,0,0,0,10-10V12A10.011,10.011,0,0,0,108,2H12m0-2h96a12,12,0,0,1,12,12v96a12,12,0,0,1-12,12H12A12,12,0,0,1,0,108V12A12,12,0,0,1,12,0Z" transform="translate(1402 652.999)" fill="#fff" />
                                    <rect id="Rectangle_1073" data-name="Rectangle 1073" width="120" height="120" rx="12" transform="translate(1402 653)" className="prism-block" />
                                    <path id="Rectangle_1073_-_Outline" data-name="Rectangle 1073 - Outline" d="M12,2A10.011,10.011,0,0,0,2,12v96a10.011,10.011,0,0,0,10,10h96a10.011,10.011,0,0,0,10-10V12A10.011,10.011,0,0,0,108,2H12m0-2h96a12,12,0,0,1,12,12v96a12,12,0,0,1-12,12H12A12,12,0,0,1,0,108V12A12,12,0,0,1,12,0Z" transform="translate(1402 653)" fill="#fff" />
                                    <rect id="Rectangle_1074" data-name="Rectangle 1074" width="101" height="2" rx="1" transform="translate(1463.5 662.5) rotate(90)" fill="#999" />
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>

                <div className="apple-secondary-wrapper">
                    <div className="about">
                        <div className="right">
                            <motion.p
                                variants={paragraphVariant}
                                initial="initial"
                                whileInView="animate"
                                viewport={{
                                    once: true,
                                    amount: 1,
                                }}
                            >
                                During my 7 years of Practice, I've come to realize that I simply enjoy working with tools. I enjoy using them and I enjoy making them.
                            </motion.p>
                            <motion.p
                                variants={paragraphVariant}
                                initial="initial"
                                whileInView="animate"
                                viewport={{
                                    once: true,
                                    amount: 1,
                                }}
                            >
                                So much that I'd get over large learning curves just to make something useful or beautiful or funny.
                            </motion.p>
                            <motion.p
                                variants={paragraphVariant}
                                initial="initial"
                                whileInView="animate"
                                viewport={{
                                    once: true,
                                    amount: 1,
                                }}
                            >
                                In this pursuit, Comedy, Design, and (actual) Branding have become a part of my Practice.
                            </motion.p>
                        </div>
                        <div className="left">
                            <motion.p
                                variants={paragraphVariant}
                                initial="initial"
                                whileInView="animate"
                                viewport={{
                                    once: true,
                                    amount: 1,
                                }}
                            >
                                For the love of tools and reverence towards <span className='underline'>all</span> the people who wield them, I want to join Apple.
                            </motion.p>
                        </div>
                    </div>
                    <motion.div className="intro"
                        variants={paragraphVariant}
                        initial="initial"
                        whileInView="animate"
                        viewport={{
                            once: true,
                            amount: 0.1,
                        }}
                    >
                        <p className="intro-name">
                            My name is
                            <span className="highlight">
                                Nihar Bhagat
                            </span>
                        </p>
                        <div className="intro-links">
                            <Link to="/work" className="intro-link">
                                Here's a small selection of my works
                            </Link>
                            <Link to="/about" className="intro-link">
                                A little about me
                            </Link>
                        </div>
                    </motion.div>
                    <div className="outro">
                        Alternatively, you can view
                        <Link to="/musings" className="outro-link">
                            my other musings
                        </Link>
                        or you can directly
                        <Link to="/contact" className="outro-link">
                            get in touch.
                        </Link>
                    </div>
                </div>
            </section>

        </Styleswrapper>
    );
}

export default Apple;
